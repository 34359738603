import $ from "jquery";

$(document).on('turbolinks:load', function () {



    $('#select_unit_id').on('change', function () {


    });



});
