///select2setup.js
import $ from 'jquery'
import 'select2/dist/css/select2.css'
import 'select2'

/// if you don't have turnbolinks then use this:
//// document.addEventListener('DOMContentLoaded', () => { 
window.addEventListener('turbolinks:load', () => {
    $('.select2').select2({
        tags: true,
        tokenSeparators: [',', ' ']
    });

    function init_select2(selector){
        $(selector).select2({
            placeholder: "Seleccción",
            allowClear: true,
            theme: "classic",
            width: 'resolve'
        });
    };

    init_select2(".detalle")

/*
    $("#details").on("cocoon:after-insert", function(_, row){
        console.log('222')
        field = $(row).find(".detalle")
        init_select2(field);
    });*/

    $(document).on('click', '.add_field', function() {
        $(".detalle").select2({
            placeholder: "Seleccion",
            allowClear: true,
            theme: "classic",
            width: 'resolve'
        });
    })

    function formatNode(node) {
        if (!node.id) {
            return "<b>" + node.text + "</b>";
        } else {
            return "<span>&nbsp;&nbsp;" + node.text + "</span>";
        }
    }

    $('#select_proyecto').select2({
        ajax: {
            url: '/get_proyectos',
            type: 'GET',
            dataType: 'json',
            processResults: function (data) {
                return {
                    results: data
                };
            }
        },
        width: '200px',
        formatResult: formatNode
    });

    var proyectSelect = $('#select_proyecto');
    var proyectId = document.getElementById('IdComponente');

    if(proyectId !== null && proyectId.value !== ''){
        $.ajax({
            type: 'GET',
            url: '/get_proyecto/' + proyectId.value
        }).then(function (data) {
            // create the option and append to Select2
            var option = new Option(data.Descripcion, data.IdComponente, true, true);
            proyectSelect.append(option).trigger('change');

            // manually trigger the `select2:select` event
            proyectSelect.trigger({
                type: 'select2:select',
                params: {
                    data: data
                }
            });
        });
    }

});
