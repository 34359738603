// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("jquery");
//require("jquery-ui");
//require("jquery-ui-dist/jquery-ui");

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

require("bootstrap");
require("bootstrap-select");
require("javascripts/scripts.bundle");
require("javascripts/tooltip");
require("@fortawesome/fontawesome-free/js/all");
require("sweetalert2");
require("components/general");
require("components/submit_forms");
require("components/users");
require("components/cocoon")
require("components/sweet-alert-confirm");


window.jQuery = $;
window.swal   = swal;


require.context('../../assets/images', true);
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
