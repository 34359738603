import React from 'react';
import ValidateVariablesRow from "./validate_variables_row";
import I18n from 'i18n-js';
I18n.locale = window.I18n.locale;

class ValidateVariablesContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { details } = this.props;
        let dismissal_variables = JSON.parse(details.variables);

        return (
            <div className='m-form m-form--group-seperator'>
                <div className="row form-group m-form__group">
                    <div className="col-md-1 text-center">
                        <label className="form-label font-weight-bold">{I18n.t('messages.validate_variables.number')}</label>
                    </div>
                    <div className="col-md-2 text-center">
                        <label className="form-label font-weight-bold">{I18n.t('messages.validate_variables.description')}</label>
                    </div>
                    <div className="col-md-2 text-center">
                        <label className="form-label font-weight-bold">{I18n.t('messages.validate_variables.motive_reject')}</label>
                    </div>
                    <div className="col-md-2 text-center">
                        <label className="form-label font-weight-bold">{I18n.t('messages.validate_variables.unit')}</label>
                    </div>
                    <div className="col-md-2 text-center">
                        <label className="form-label font-weight-bold">{I18n.t('messages.validate_variables.unit_pres')}</label>
                    </div>
                    <div className="col-md-1 text-center">
                        <label className="form-label font-weight-bold">{I18n.t('messages.validate_variables.status')}</label>
                    </div>
                    <div className="col-md-2 text-center">
                        <label className="form-label font-weight-bold">{I18n.t('messages.validate_variables.actions')}</label>
                    </div>
                </div>
                { dismissal_variables.map((dismissal_variable, i) =>
                    <ValidateVariablesRow data={dismissal_variable} dismissalId={dismissal_variables.dismissal_id}
                                         index={i} user={details.user} key={i} validateStatusId={details.validate_status_id}
                                         rejectStatusId={details.reject_status_id} unitPresIds={details.unit_pres_ids}/>
                  )
                }
            </div>
        )
    }
}

export default ValidateVariablesContainer;
