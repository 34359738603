import $ from "jquery";

$(document).on('turbolinks:load', function () {
    let timerKey;
    let searchRequestByKey;
    let delay = 1000;
    let $employeeFirstName = $('input#employee_first_name');
    let $employeeLastName = $('input#employee_last_name');
    let $employeeEmail = $('input#employee_email');
    let $employeeFirstNameHidden = $('input#employee_first_name_h');
    let $employeeLastNameHidden = $('input#employee_last_name_h');
    let $employeeEmailHidden = $('input#employee_email_h');
    let employeeKey = document.getElementById('employee_key');
    let $submitButton = $('input#submit_new_user');

    $('#user_role_id').change(function() {
        let roleKey = $(this).find('option:selected').data('key');
        let $fieldsCancelationEmployees = $('.fields_cancelation_employees');

        if (roleKey === 'cancelation_employees') {
            $fieldsCancelationEmployees.removeClass('m--hide');
        } else {
            $fieldsCancelationEmployees.addClass('m--hide');
        }
    });

    let resquestName = (cve_empleado) => {
        if (cve_empleado.length > 0) {
            $submitButton.attr('disabled', 'disabled');
            searchRequestByKey = $.ajax({
                url: `/api/v1/employees/get_name/${cve_empleado}`,
                method: 'GET',
                success: (data) => {
                    console.log(data);
                    if (data !== null) {
                        $employeeFirstName.attr('value', data.first_name);
                        $employeeLastName.attr('value', data.last_name);
                        $employeeEmail.attr('value', data.email);
                        $employeeFirstNameHidden.attr('value', data.first_name);
                        $employeeLastNameHidden.attr('value', data.last_name);
                        $employeeEmailHidden.attr('value', data.email);
                        if (data.email !== null){
                            $submitButton.removeAttr('disabled');
                        }
                    }
                },
                error: (error) => {
                    $employeeFirstName.attr('value', '');
                    $employeeLastName.attr('value', '');
                    $employeeEmail.attr('value', '');
                    $submitButton.attr('disabled', 'disabled');
                },
                complete: () => {
                }
            });
        }
    };

    if (employeeKey !== null) {
        employeeKey.addEventListener('input', function () {
            let cve_empleado = $(this).val();
            $employeeFirstName.attr('value', '');
            $employeeLastName.attr('value', '');
            $employeeEmail.attr('value', '');
            $submitButton.attr('disabled', 'disabled');

            window.clearTimeout(timerKey);
            searchRequestByKey && searchRequestByKey.abort();
            timerKey = window.setTimeout(function () {
                resquestName(cve_empleado);
            }, delay);
        });

        let cve_empleado = $(employeeKey).val();
        if (cve_empleado !== '') {
            resquestName(cve_empleado);
        }
    }


    /* Evaluation for hidde or show participants on User/edit */
    const contTypeSelect = document.querySelector("#user_event_contribution_type");

    const fieldsToShow = function (value){
        var cont_videos         = $('#videos');
        var cont_authors        = $('#authors');
        var cont_participants   = $('#participants');
        var inputs_authors      = $('#authors > .form-group > input');
        var inputs_participants = $('#participants > .form-group > input');
        switch(value){
            case 'videos':
                cont_videos.removeClass('hidden');
                cont_authors.removeClass('hidden');
                cont_participants.addClass('hidden');
                inputs_participants.attr("disabled",true);
                inputs_authors.attr("disabled",false);
                break;
            case 'paneles':
                cont_videos.addClass('hidden');
                cont_authors.addClass('hidden');
                cont_participants.removeClass('hidden');
                inputs_authors.attr("disabled",true);
                inputs_participants.attr("disabled",false);
                break;
            case 'coloquios':
                cont_videos.addClass('hidden');
                cont_authors.addClass('hidden');
                cont_participants.removeClass('hidden');
                inputs_authors.attr("disabled",true);
                inputs_participants.attr("disabled",false);
                break;
            default:
                cont_videos.addClass('hidden');
                cont_authors.removeClass('hidden');
                cont_participants.addClass('hidden');
                inputs_participants.attr("disabled",true);
                inputs_authors.attr("disabled",false);
                break;
        }
    };

    if(contTypeSelect){
        fieldsToShow(contTypeSelect.value);
        contTypeSelect.addEventListener("change", () => {
            fieldsToShow(contTypeSelect.value);
        });
    }



    /* CODE FOR DYNAMIC FIELDS IN CONTRIBUTION * /

    window.contributionTypeChange = function(){
        var value = $('#user_event_contribution_type').val();
        if(value === 'videos'){
            $('#videos').removeClass('hidden');
        }else{
            $('#videos').addClass('hidden');
        }
        $.ajax({
            url: "/participant_types",
            type: "GET",
            dataType: "json",
            data: { cont_type_selected: value },
            success: function(data) {
                updateParticipantTypes(data);
            }
        });
    };

    function updateParticipantTypes(p_types) {
        var selects = $('select[class*="participant_types"]');
        if(selects){
            $.each(selects, function(index, select_input) {
                select_input.options.length = 0; // Clear all options
                p_types.forEach(function(p_type) {
                    var option = document.createElement("option");
                    option.value = p_type.id;
                    option.text = p_type.name;
                    select_input.add(option);
                });
            });
        }
    }

    /********************************************************/

});
