import $ from "jquery";
import swal from "sweetalert";
import I18n from "i18n-js";

$(document).on('turbolinks:load', function () {
    const swalOpts = {
        buttons: {
            cancel: I18n.t('messages.cancel'),
            confirm: {
                text: I18n.t('messages.confirm'),
                closeModal: true
            }
        }
    };

    $('#enable_dismissal_review').click(function(event) {
        event.preventDefault();

        let element, resourceMsg;
        element = $(this);
        resourceMsg = element.data('resource-message');
        swal(resourceMsg, swalOpts).then(function(isConfirm) {
            if (!isConfirm) {
                return;
            }
            let $addVariablesForm = $('#add_variables_form');
            $addVariablesForm.find('#enable_revision').val(1);
            $addVariablesForm.submit();
        });
    });

    $('#liberate_dismissal').click(function(event) {
        event.preventDefault();

        let element, resourceMsg;
        element = $(this);
        resourceMsg = element.data('resource-message');
        swal(resourceMsg, swalOpts).then(function(isConfirm) {
            if (!isConfirm) {
                return;
            }
            let $form = $('#liberate_form');
            $form.submit();
        });
    });
});
