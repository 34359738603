import React from 'react';
import ReactDOM from 'react-dom';
import ValidateVariablesContainer from "./validate_variables/validate_variables_container";
import $ from "jquery";

$(document).on('turbolinks:load', function () {
    let validateContainer = document.getElementById('validate-variables-container');
    if (validateContainer !== null) {
        let details = JSON.parse(validateContainer.dataset.details);
        let component = <ValidateVariablesContainer details={ details } />;
        ReactDOM.render(component, validateContainer);
    }
});
