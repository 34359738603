import $ from "jquery";
import swal from "sweetalert";
import I18n from "i18n-js";

$(document).on('turbolinks:load', function () {

    /*REGISTRATION FORM*/
    const regForm             = document.querySelector('form#pre-registration');

    const curpEl              = document.querySelector('#curp');
    const firstNameEl         = document.querySelector('#first_name');
    const lastNameEl          = document.querySelector('#last_name');
    const maidennameEl        = document.querySelector('#maiden_name');
    const genderEl            = document.querySelector('#gender');
    const stateEl             = document.querySelector('#state');
    const phoneEl             = document.querySelector('#phone');
    const participationTypeEl = document.querySelector('#participationType');
    const modalityEl          = document.querySelector('#modality');
    const emailEl             = document.querySelector('#email');
    const confirmEmailEl      = document.querySelector('#confirm-email');
    const passwordEl          = document.querySelector('#password');
    const confirmPasswordEl   = document.querySelector('#confirm-password');

    const institutionEl       = document.querySelector('#institution');
    const institutionSupportEl= document.querySelector('#institutionSupport');
    const jobEl               = document.querySelector('#job');
    const academicLevelEl     = document.querySelector('#academicLevel');
    /******************/

    /*CONTRIBUTION FORM*/
    const contributionForm    = document.querySelector('form#contribution-form');
    const contTitleEl         = document.querySelector('#cont-title');
    const contFile1dEl         = document.querySelector('#field-contribution-1');
    const contFile2dEl         = document.querySelector('#field-contribution-2');
    /******************/

    /*PASSWORD RECOVERY FORM*/
    const passwordForm = document.querySelector('form#password-form');
    /************************/

    /*PAYMENT FORMS*/
    const paymentForm   = document.querySelector('form#payment-form');
    const invoiceForm   = document.querySelector('form#invoice-form');
    const fileReqEl     = document.querySelector('#file-required');
    const invoiceNameEl = document.querySelector('#invoice-name');
    const invoiceEmailEl= document.querySelector('#invoice-email');
    const filePresEl    = document.querySelector('a#file-present');
    const fileInvReqEl  = document.querySelector('#file-invoice-required');
    const fileInvPresEl = document.querySelector('a#file-invoice-present');
    /************************/

    /* REVIEW FORMS */
    const reviewForm      = document.querySelector('form#review-form');
    const reviewCommentEl = document.querySelector('#review-comment');
    /****************/

    const isRequired = value => value === '' ? false : true;

    const isEmailValid = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };

    const isPasswordSecure = (password) => {
        const re = new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.{8,})");
        return re.test(password);
    };

    const showError = (input, message) => {
        // get the form-field element
        const formField = input.parentElement;
        // add the error class
        formField.classList.remove('success');
        formField.classList.add('error');

        // show the error message
        const error = formField.querySelector('small');
        error.textContent = message;
    };

    const showSuccess = (input) => {
        // get the form-field element
        const formField = input.parentElement;
        //console.log(formField);
        // remove the error class
        formField.classList.remove('error');
        formField.classList.add('success');

        // hide the error message
        const error = formField.querySelector('small');
        error.textContent = '';
    };

    const checkCurp = () => {

        let valid = false;
        const min = 18;
        const curp = curpEl.value.trim();

        if (!isRequired(curp)) {
            showError(curpEl, 'Es necesario ingresar CURP.');
        } else if ( curp.length < min ) {
            showError(curpEl, `La CURP debe contener al menos ${min} caracteres.`)
        } else {
            showSuccess(curpEl);
            requestCurpData(curp);
            valid = true;
        }
        return valid;
    };

    const checkName = () => {
        let valid = false;
        const firstName = firstNameEl.value.trim();

        if (!isRequired(firstName)) {
            showError(firstNameEl, 'Es necesario ingresar el nombre.');
        } else {
            showSuccess(firstNameEl);
            valid = true;
        }
        return valid;
    };

    const checkLastName = () => {
        let valid = false;
        const lastName = lastNameEl.value.trim();

        if (!isRequired(lastName)) {
            showError(lastNameEl, 'Es necesario ingresar el apellido paterno.');
        } else {
            showSuccess(lastNameEl);
            valid = true;
        }
        return valid;
    };

    const checkGender = () => {
        let valid = false;
        const gender = genderEl.value.trim();

        if (!isRequired(gender)) {
            showError(genderEl, 'Es necesario ingresar el genero.');
        } else {
            showSuccess(genderEl);
            valid = true;
        }
        return valid;
    };

    const checkState = () => {
        let valid = false;
        const state = stateEl.value.trim();

        if (!isRequired(state)) {
            showError(stateEl, 'Es necesario ingresar la entidad.');
        } else {
            showSuccess(stateEl);
            valid = true;
        }
        return valid;
    };

    const checkPhone = () => {

        let valid = false;
        const phone = phoneEl.value.trim();

        if (!isRequired(phone)) {
            showError(phoneEl, 'Es necesario ingresar télefono.');
        } else {
            showSuccess(phoneEl);
            valid = true;
        }
        return valid;
    };

    const checkParticipationType = () => {
        let valid = false;
        const participationType = participationTypeEl.value.trim();

        if (!isRequired(participationType)) {
            showError(participationTypeEl, 'Es necesario ingresar el tipo de participación.');
        } else {
            showSuccess(participationTypeEl);
            valid = true;
        }
        return valid;
    };

    const checkModality = () => {
        let valid = false;
        const modality = modalityEl.value.trim();

        if (!isRequired(modality)) {
            showError(modalityEl, 'Es necesario ingresar la modalidad.');
        } else {
            showSuccess(modalityEl);
            valid = true;
        }
        return valid;
    };

    const checkEmail = () => {
        let valid = false;
        const email = emailEl.value.trim();
        if (!isRequired(email)) {
            showError(emailEl, 'Es necesario ingresar un email.');
        } else if (!isEmailValid(email)) {
            showError(emailEl, 'Email es invalido.')
        } else {
            showSuccess(emailEl);
            valid = true;
        }
        return valid;
    };

    const checkConfirmEmail = () => {
        let valid = false;
        // check confirm email
        const confirmEmail  = confirmEmailEl.value.trim();
        const email         = emailEl.value.trim();

        if (!isRequired(confirmEmail)) {
            showError(confirmEmailEl, 'Por favor ingresa email de nuevo.');
        } else if (email !== confirmEmail) {
            showError(confirmEmailEl, 'Email de confirmación no coincide.');
        } else {
            showSuccess(confirmEmailEl);
            valid = true;
        }

        return valid;
    };

    const checkPassword = () => {

        let valid = false;

        const password = passwordEl.value.trim();

        if (!isRequired(password)) {
            showError(passwordEl, 'Es necesario ingresar contraseña.');
        } else if (!isPasswordSecure(password)) {
            showError(passwordEl, 'Contraseña debe tener al menos 8 caracteres que incluya al menos 1 caracter en minuscula y 1 número');
        } else {
            showSuccess(passwordEl);
            valid = true;
        }

        return valid;
    };

    const checkConfirmPassword = () => {
        let valid = false;
        // check confirm password
        const confirmPassword = confirmPasswordEl.value.trim();
        const password = passwordEl.value.trim();

        if (!isRequired(confirmPassword)) {
            showError(confirmPasswordEl, 'Por favor ingresa de nuevo tu contraseña.');
        } else if (password !== confirmPassword) {
            showError(confirmPasswordEl, 'Contraseña de confirmación no coincide.');
        } else {
            showSuccess(confirmPasswordEl);
            valid = true;
        }

        return valid;
    };

    const checkInstitution = () => {
        let valid = false;
        // check confirm password
        const institution = institutionEl.value.trim();

        if (!isRequired(institution)) {
            showError(institutionEl, 'Es necesario ingresar la institución.');
        } else {
            showSuccess(institutionEl);
            valid = true;
        }
        return valid;

    };

    const checkInstitutionSupport = () => {
        let valid = false;
        // check confirm password
        const institutionSupport = institutionSupportEl.value.trim();

        if (!isRequired(institutionSupport)) {
            showError(institutionSupportEl, 'Es necesario ingresar el tipo de sostenimiento.');
        } else {
            showSuccess(institutionSupportEl);
            valid = true;
        }
        return valid;

    };

    const checkJob = () => {
        let valid = false;
        // check confirm password
        const job = jobEl.value.trim();

        if (!isRequired(job)) {
            showError(jobEl, 'Es necesario ingresar el puesto.');
        } else {
            showSuccess(jobEl);
            valid = true;
        }
        return valid;
    };


    const checkAcademicLevel = () => {
        let valid = false;
        // check confirm password
        const academicLevel = academicLevelEl.value.trim();

        if (!isRequired(academicLevel)) {
            showError(academicLevelEl, 'Es necesario ingresar el nivel académico.');
        } else {
            showSuccess(academicLevelEl);
            valid = true;
        }
        return valid;
    };


    const checkTitle = () => {
        let valid = false;
        const contTitle = contTitleEl.value.trim();

        if (!isRequired(contTitle)) {
            showError(contTitleEl, 'Es necesario ingresar el nombre.');
        } else {
            showSuccess(contTitleEl);
            valid = true;
        }
        return valid;
    };

    const checkParticipants = () => {
        let valid = false;

        const contParticipantsEl  = $('.participants-fields:not(.hidden)');
        const p_fields_row = contParticipantsEl.find('.participant-fields-row');

        p_fields_row.each((index, element) => {
            const inputs = $(element).find('.field-name, .field-email, .field-institution, .field-academic-lvl');

            const emptyInputs = inputs.filter(function() {
                return $(this).val() === '';
            });

            if (emptyInputs.length === 0) {
                valid = true;
            }
        });

        return valid;
    };

    const checkFile = () => {
        let valid = false;
        const contFile = contFile1dEl.value.trim();
        if($('#contribution_1_url').val() === 'true'){
            valid = true;
        }else{
            if (!isRequired(contFile)) {
                showError(contFile1dEl, 'Es necesario ingresar un archivo y menor a 1 MB.');
            } else {
                showSuccess(contFile1dEl);
                valid = true;
            }
        }
        return valid;
    };

    const checkFile2 = () => {
        let valid = false;
        const contFile = contFile2dEl.value.trim();
        if($('#contribution_2_url').val() === 'true'){
            valid = true;
        }else{
            if (!isRequired(contFile)) {
                showError(contFile2dEl, 'Es necesario ingresar un archivo y menor a 1 MB.');
            } else {
                showSuccess(contFile2dEl);
                valid = true;
            }
        }
        return valid;
    };

    const checkFilePresence= (form_type) => {
        let valid = false;
        if(form_type === 'payment'){
            const fileReq  = fileReqEl.value.trim();

            if($('#file-required').val() === 'true' || filePresEl){
                valid = true;
            }else{
                if (!isRequired(fileReq)) {
                    showError(fileReqEl, 'Es necesario ingresar un archivo y menor a 1 MB.');
                } else {
                    showSuccess(fileReqEl);
                    valid = true;
                }
            }
        }else{
            const fileReq  = fileInvReqEl.value.trim();

            if($('#file-invoice-required').val() === 'true' || fileInvPresEl){
                valid = true;
            }else{
                if (!isRequired(fileReq)) {
                    showError(fileInvReqEl, 'Es necesario ingresar un archivo, y menor a 1 MB.');
                } else {
                    showSuccess(fileInvReqEl);
                    valid = true;
                }
            }
        }

        return valid;
    };

    const checkInvoiceFields = (field_name) => {
        let valid           = false;
        let invoiceField    = null;

        if(field_name === 'name'){
            invoiceField  = invoiceNameEl.value.trim();
        }else{
            invoiceField  = invoiceEmailEl.value.trim();
        }

        if (!isRequired(invoiceField)) {
            if(field_name === 'name'){
                showError(invoiceNameEl, 'Es necesario ingresar el nombre.');
            }else{
                showError(invoiceEmailEl, 'Es necesario ingresar un correo electronico.');
            }
        } else {
            if(field_name === 'name'){
                showSuccess(invoiceNameEl);
            }else{
                showSuccess(invoiceEmailEl);
            }
            valid = true;
        }
        return valid;
    };

    const checkReviewFields = () => {
        var button = document.getElementById("review-submit");

        let valid = false;
        let noChars = 200;

        // check confirm password
        const reviewComment = reviewCommentEl.value.trim();

        if (!isRequired(reviewComment) || reviewComment.length < 200) {
            console.log('error');
            noChars = 200-reviewComment.length;
            showError(reviewCommentEl, 'Es necesario ingresar al menos 200 caracteres, faltan '+ noChars +'.');
            button.disabled = true;
            button.classList.add("disabled");
        } else {
            console.log('right');
            showSuccess(reviewCommentEl);
            valid = true;
            button.disabled = false;
            button.classList.remove("disabled");
        }
        return valid;
    };



    // Waits for user to pause typing before validate
    const debounce = (fn, delay = 500) => {
        let timeoutId;
        return (...args) => {
            // cancel the previous timer
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            // setup a new timer
            timeoutId = setTimeout(() => {
                fn.apply(null, args)
            }, delay);
        };
    };

    if(regForm){
        regForm.addEventListener('input', debounce(function (e) {
            switch (e.target.id) {
                case 'curp':
                    checkCurp();
                    break;
                case 'first_name':
                    checkName();
                    break;
                case 'last_name':
                    checkLastName();
                    break;
                case 'phone':
                    checkPhone();
                    break;
                case 'participationType':
                    checkParticipationType();
                    break
                case 'email':
                    checkEmail();
                    break;
                case 'confirm-email':
                    checkConfirmEmail();
                    break;
                case 'password':
                    checkPassword();
                    break;
                case 'confirm-password':
                    checkConfirmPassword();
                    break;
                case 'institution':
                    checkInstitution();
                    break;
                case 'institutionSupport':
                    checkInstitutionSupport();
                    break;
                case 'job':
                    checkJob();
                    break;
                case 'academicLevel':
                    checkAcademicLevel();
                    break;
            }
        }));
    }

    if(contributionForm){
        contributionForm.addEventListener('input', debounce(function (e) {
            switch (e.target.id) {
                case 'cont-title':
                    checkTitle();
                    break;
            }
        }));
    }

    if(passwordForm){
        passwordForm.addEventListener('input', debounce( function(e){
            console.log(e.target.id);
            switch (e.target.id) {
                case 'password':
                    checkPassword();
                    break;
                case 'confirm-password':
                    checkConfirmPassword();
                    break;
            }
        }));
    }

    if(invoiceForm){
        invoiceForm.addEventListener('input', debounce(function (e) {
            switch (e.target.id) {
                case 'invoice-name':
                    checkInvoiceFields('name');
                    break;
                case 'invoice-email':
                    checkInvoiceFields('email');
                    break;
            }
        }));
    }

    if(reviewForm){
        reviewForm.addEventListener('input', debounce(function (e) {
            switch (e.target.id) {
                case 'review-comment':
                    checkReviewFields();
                    break;
            }
        }));
    }


    /** REGISTRATION FORM **/
    $('#reg-submit').click(function(e){
        // validate forms
        if(!e.detail || e.detail == 1){
            //isCurpValid = checkCurp(),
            let
                isNameValid = checkName(),
                isLastNameValid = checkLastName(),
                isGeneroValid = checkGender(),
                isState = checkState(),
                isPhoneValid = checkPhone(),
                isParticipationType = checkParticipationType(),
                isModality = checkModality(),
                isEmailValid = checkEmail(),
                isConfirmEmailValid = checkConfirmEmail(),
                isPasswordValid = checkPassword(),
                isConfirmPasswordValid = checkConfirmPassword(),
                isInstitution = checkInstitution(),
                isInstitutionSupport = checkInstitutionSupport(),
                isJob = checkJob(),
                isAcademicLevel = checkAcademicLevel();

            //isCurpValid &&
            let isFormValid = isNameValid && isLastNameValid && isGeneroValid && isState &&
                isPhoneValid && isParticipationType && isModality &&
                isEmailValid &&
                isConfirmEmailValid &&
                isPasswordValid &&
                isConfirmPasswordValid &&
                isInstitution &&
                isInstitutionSupport &&
                isJob &&
                isAcademicLevel;

            if(isFormValid){
                swal({
                    title: 'CONFIRMAR REGISTRO',
                    icon: 'info',
                    text: 'Al confirmar este registro recibirás un correo electrónico con información importante y accesos para realizar el pago y cargar contribuciones.',
                    buttons: {
                        cancel: I18n.t('messages.cancel'),
                        confirm: I18n.t('messages.confirm')
                    }
                }).then((isConfirmed) => {
                    if (isConfirmed) {
                        // Select the form element
                        var formReg = $('form#pre-registration');

                        // Bind a function to the submit event
                        formReg.submit(function(event) {
                            // Prevent the default form submission behavior
                            event.preventDefault();

                            swal({
                                title: "Enviando...",
                                text: "Por favor espera",
                                icon: "info",
                                //showConfirmButton: false,
                                buttons:           false,
                                allowOutsideClick: false
                            });

                            // Send an AJAX request to submit the form data
                            $.ajax({
                                url: formReg.attr('action'),
                                type: formReg.attr('method'),
                                data: formReg.serialize(),
                                success: function(response) {
                                    swal({
                                        title: "¡REGISTRO COMPLETADO!",
                                        text: "Se ha enviado un correo electrónico de confirmación con información importante",
                                        icon: 'success',
                                        confirmButtonText: 'Aceptar',
                                        showConfirmButton: true
                                    }).then(function() {
                                        window.location = "/";
                                    });
                                },
                                error: function(xhr, status, error) {
                                    let msj_error = ''
                                    if(xhr.responseText !== undefined || xhr.responseText !== '')
                                    {
                                        $.each(xhr.responseJSON, function(index, value) {
                                            msj_error += '' + index + ': ' + value + '\n'
                                        });
                                    }
                                    swal({
                                        title: "Hubo un error al registrar, intenta de nuevo...\n ",
                                        text: `${msj_error}`,
                                        icon: 'error',
                                        confirm: {
                                            text: "Aceptar",
                                            value: true,
                                            visible: true,
                                            className: "",
                                            closeModal: true
                                        }
                                    });
                                }
                            });
                        });

                        formReg.submit();
                    }
                }).catch(e.preventDefault());
            }else{
                swal({
                    title: "¡UPS!",
                    text: "Parece que aún hay campos por llenar, verifica tu información e intenta de nuevo.",
                    icon: 'warning',
                    confirm: {
                        text: "Aceptar 123",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                });
            }
        }

    });

    /**CONTRIBUTION FORM*/
    $('#contribution-submit').click(function(e){
        // validate forms
        if(!e.detail || e.detail == 1){
            let isTitleValid        = checkTitle();
            let isParticipantsValid = checkParticipants();
            let isFile1Valid         = checkFile();
            let isFile2Valid         = checkFile2();

            let isFormValid = isTitleValid && isFile1Valid && isFile2Valid;

            if(isFormValid){
                if(isParticipantsValid){
                    swal({
                        title: 'CONFIRMAR GUARDADO',
                        icon: 'info',
                        text: 'Al confirmar se guardará los datos ingresados para esta contribución.',
                        buttons: {
                            cancel: I18n.t('messages.cancel'),
                            confirm: I18n.t('messages.confirm')
                        }
                    }).then((isConfirmed) => {
                        if (isConfirmed) {
                            // Select the form element
                            var formReg = $('form#contribution-form');

                            // Bind a function to the submit event
                            formReg.submit(function(event) {
                                // Prevent the default form submission behavior
                                event.preventDefault();

                                /*swal({
                                    title: "Enviando...",
                                    text: "Por favor espera",
                                    icon: "info",
                                    buttons:           false,
                                    allowOutsideClick: false
                                });*/

                                // APPEND FILES FOR AJAX SUBMIT FORM
                                var formData = new FormData(formReg[0]);
                                var file1Input = $('#field-contribution-1')[0];
                                var file2Input = $('#field-contribution-2')[0];

                                if (file1Input.files.length > 0) {
                                    formData.append('contribution_1', file1Input.files[0]);
                                }
                                if (file2Input.files.length > 0) {
                                    formData.append('contribution_2', file2Input.files[0]);
                                }

                                // Send an AJAX request to submit the form data
                                $.ajax({
                                    url: formReg.attr('action'),
                                    type: formReg.attr('method'),
                                    data: formData,
                                    processData: false,
                                    contentType: false,
                                    beforeSend: function() {
                                        // This code will execute before the AJAX request is sent
                                        // Show a loading indicator to indicate that the form submission is being processed
                                        swal({
                                            title: "Procesando...",
                                            text: "Espere un momento por favor",
                                            icon: 'info',
                                            buttons: false,
                                            closeOnClickOutside: false,
                                            closeOnEsc: false,
                                            allowOutsideClick: false,
                                            allowEnterKey: false,
                                            closeModal: false
                                        });
                                    },
                                    success: function(response) {
                                        swal({
                                            title: "¡COMPLETADO!",
                                            text: '              ',
                                            icon: 'success',
                                            confirmButtonText: 'Aceptar',
                                            buttons: false,
                                            closeOnClickOutside: false,
                                            closeOnEsc: false,
                                            allowOutsideClick: false,
                                            allowEnterKey: false,
                                            closeModal: false
                                        }).then(function() {
                                            setTimeout(function() {
                                                window.location = "/contribuciones";
                                            }, 2000);
                                        });
                                    },
                                    error: function(xhr, status, error) {
                                        let msj_error = '';
                                        if(xhr.responseText !== undefined || xhr.responseText !== '')
                                        {
                                            $.each(xhr.responseJSON, function(index, value) {
                                                msj_error += '' + index + ': ' + value + '\n'
                                            });
                                        }
                                        swal({
                                            title: "Hubo un error al guardar, intenta de nuevo...\n ",
                                            text: `${msj_error}`,
                                            icon: 'error',
                                            confirm: {
                                                text: "Aceptar",
                                                value: true,
                                                visible: true,
                                                className: "",
                                                closeModal: true
                                            }
                                        });
                                    },
                                    complete: function() {
                                        // This code will execute after the AJAX request is completed, regardless of success or error
                                        // Close the loading indicator
                                        swal.close();
                                    }
                                });
                            });

                            formReg.submit();
                        }
                    }).catch(e.preventDefault());
                }else{
                    swal({
                        title: "¡UPS!",
                        text: "Es necesario llenar todos los campos para al menos un Autor, Participante o Moderador.",
                        icon: 'warning',
                        confirm: {
                            text: "Aceptar",
                            value: true,
                            visible: true,
                            className: "",
                            closeModal: true
                        }
                    });
                }
            }else{
                swal({
                    title: "¡UPS!",
                    text: "Parece que aún hay campos por llenar, verifica tu información e intenta de nuevo.",
                    icon: 'warning',
                    confirm: {
                        text: "Aceptar",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                });
            }
        }

    });

    /**PAYMENT FORM*/
    $('#payment-submit').click(function(e){
        // validate forms
        if(!e.detail || e.detail == 1){
            let isFileValid = checkFilePresence('payment');

            let isFormValid = isFileValid;
            if(isFormValid){
                swal({
                    title: 'CONFIRMAR GUARDADO',
                    icon: 'info',
                    text: 'Al confirmar se enviarán los datos ingresados sobre la cuota de recuperación realizada.',
                    buttons: {
                        cancel: I18n.t('messages.cancel'),
                        confirm: I18n.t('messages.confirm')
                    }
                }).then((isConfirmed) => {
                    if (isConfirmed) {
                        // Select the form element
                        var formPayment = $('form#payment-form');

                        // Bind a function to the submit event
                        formPayment.submit(function(event) {
                            // Prevent the default form submission behavior
                            event.preventDefault();

                            // APPEND FILES FOR AJAX SUBMIT FORM
                            var formData = new FormData(formPayment[0]);
                            var fileInput = $('#file-required')[0];

                            if (fileInput.files.length > 0) {
                                formData.append('payment_file', fileInput.files[0]);
                            }

                            // Send an AJAX request to submit the form data
                            var ajaxRequest = $.ajax({
                                url: formPayment.attr('action'),
                                type: formPayment.attr('method'),
                                data: formData,
                                processData: false,
                                contentType: false,
                                beforeSend: function() {
                                    // This code will execute before the AJAX request is sent
                                    // Show a loading indicator to indicate that the form submission is being processed
                                    swal({
                                        title: "Procesando...",
                                        text: "Espere un momento por favor",
                                        icon: 'info',
                                        buttons: false,
                                        closeOnClickOutside: false,
                                        closeOnEsc: false,
                                        allowOutsideClick: false,
                                        allowEnterKey: false,
                                        closeModal: false
                                    });
                                },
                                success: function(response) {
                                    var countdown = 7;

                                    swal({
                                        title: "¡COMPLETADO!",
                                        text: "Ha guardado su comprobante; en un lapso no mayor a 2 días hábiles le llegará un correo informando la validación de su cuota de recuperación y su pase de ingreso. ("+ countdown +")",
                                        icon: 'success',
                                        timer: 7000,
                                        buttons: false,
                                        showConfirmButton: false
                                    });

                                    var countdownTimer = setInterval(function() {
                                        countdown--;
                                        swal({
                                            title: "¡COMPLETADO!",
                                            text: "Ha guardado su comprobante; en un lapso no mayor a 2 días hábiles le llegará un correo informando la validación de su cuota de recuperación y su pase de ingreso. ("+ countdown +")",
                                            icon: 'success',
                                            timer: 1000,
                                            buttons: false,
                                            showConfirmButton: false
                                        });

                                        if (countdown <= 0) {
                                            clearInterval(countdownTimer);
                                            swal.close();
                                            // Perform any actions after the countdown completes
                                            window.location = "/informacion_cuota_recuperacion";
                                        }
                                    }, 1000);
                                },
                                error: function(xhr, status, error) {
                                    let msj_error = '';
                                    if(xhr.responseText !== undefined || xhr.responseText !== '')
                                    {
                                        $.each(xhr.responseJSON, function(index, value) {
                                            msj_error += '' + index + ': ' + value + '\n'
                                        });
                                    }
                                    swal({
                                        title: "Hubo un error al guardar, intenta de nuevo...\n ",
                                        text: `${msj_error}`,
                                        icon: 'error',
                                        confirm: {
                                            text: "Aceptar",
                                            value: true,
                                            visible: true,
                                            className: "",
                                            closeModal: true
                                        }
                                    });
                                },
                                complete: function() {
                                    // This code will execute after the AJAX request is completed, regardless of success or error
                                    // Close the loading indicator
                                    swal.close();
                                }
                            });
                        });

                        formPayment.submit();
                    }
                }).catch(e.preventDefault());
            }else{
                swal({
                    title: "¡UPS!",
                    text: "Parece que aún hay campos por llenar, verifica tu información e intenta de nuevo.",
                    icon: 'warning',
                    confirm: {
                        text: "Aceptar",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                });
            }
        }

    });

    /**INVOICE FORM*/
    $('#invoice-submit').click(function(e){
        // validate forms
        if(!e.detail || e.detail == 1){
            let isFileValid   = checkFilePresence('invoice');
            let isNameValid   = checkInvoiceFields('name');
            let isEmailValid  = checkInvoiceFields('email');

            let isFormValid = isFileValid && isNameValid && isEmailValid;
            if(isFormValid){
                swal({
                    title: 'CONFIRMAR GUARDADO',
                    icon: 'info',
                    text: 'Al confirmar se enviarán los datos ingresados para solicitud de factura.',
                    buttons: {
                        cancel: I18n.t('messages.cancel'),
                        confirm: I18n.t('messages.confirm')
                    }
                }).then((isConfirmed) => {
                    if (isConfirmed) {
                        // Select the form element
                        var formInvoice = $('form#invoice-form');

                        // Bind a function to the submit event
                        formInvoice.submit(function(event) {
                            // Prevent the default form submission behavior
                            event.preventDefault();

                            // APPEND FILES FOR AJAX SUBMIT FORM
                            var formData = new FormData(formInvoice[0]);
                            var TaxSituationInput = $('#file-invoice-required')[0];
                            var PaymentFileInput = $('#file-required')[0];

                            if (TaxSituationInput.files.length > 0) {
                                formData.append('tax_situation', TaxSituationInput.files[0]);
                            }

                            /*if (PaymentFileInput.files.length > 0) {
                                formData.append('payment_file', PaymentFileInput.files[0]);
                            }*/


                            // Send an AJAX request to submit the form data
                            $.ajax({
                                url: formInvoice.attr('action'),
                                type: formInvoice.attr('method'),
                                data: formData,
                                processData: false,
                                contentType: false,
                                beforeSend: function() {
                                    // This code will execute before the AJAX request is sent
                                    // Show a loading indicator to indicate that the form submission is being processed
                                    swal({
                                        title: "Procesando...",
                                        text: "Espere un momento por favor",
                                        icon: 'info',
                                        buttons: false,
                                        closeOnClickOutside: false,
                                        closeOnEsc: false,
                                        allowOutsideClick: false,
                                        allowEnterKey: false,
                                        closeModal: false
                                    });
                                },
                                success: function(response) {
                                    var countdown = 7;

                                    swal({
                                        title: "¡COMPLETADO!",
                                        text: "Ha guardado su comprobante; en un lapso no mayor a 2 días hábiles le llegará un correo informando la validación de su cuota de recuperación y su pase de ingreso. ("+ countdown +")",
                                        icon: 'success',
                                        timer: 7000,
                                        buttons: false,
                                        showConfirmButton: false
                                    });

                                    var countdownTimer = setInterval(function() {
                                        countdown--;
                                        swal({
                                            title: "¡COMPLETADO!",
                                            text: "Ha guardado su comprobante; en un lapso no mayor a 2 días hábiles le llegará un correo informando la validación de su cuota de recuperación y su pase de ingreso. ("+ countdown +")",
                                            icon: 'success',
                                            timer: 1000,
                                            buttons: false,
                                            showConfirmButton: false
                                        });

                                        if (countdown <= 0) {
                                            clearInterval(countdownTimer);
                                            swal.close();
                                            // Perform any actions after the countdown completes
                                            window.location = "/solicitar_factura";
                                        }
                                    }, 1000);
                                    /*swal({
                                        title: "¡COMPLETADO!",
                                        text: '              ',
                                        icon: 'success',
                                        confirmButtonText: 'Aceptar',
                                        buttons: false,
                                        closeOnClickOutside: false,
                                        closeOnEsc: false,
                                        allowOutsideClick: false,
                                        allowEnterKey: false,
                                        closeModal: false
                                    }).then(function() {
                                        setTimeout(function() {
                                            window.location = "/informacion_donaciones";
                                        }, 2000);
                                    });*/
                                },
                                error: function(xhr, status, error) {
                                    let msj_error = '';
                                    if(xhr.responseText !== undefined || xhr.responseText !== '')
                                    {
                                        $.each(xhr.responseJSON, function(index, value) {
                                            msj_error += '' + index + ': ' + value + '\n'
                                        });
                                    }
                                    swal({
                                        title: "Hubo un error al guardar, intenta de nuevo...\n ",
                                        text: `${msj_error}`,
                                        icon: 'error',
                                        confirm: {
                                            text: "Aceptar",
                                            value: true,
                                            visible: true,
                                            className: "",
                                            closeModal: true
                                        }
                                    });
                                },
                                complete: function() {
                                    // This code will execute after the AJAX request is completed, regardless of success or error
                                    // Close the loading indicator
                                    swal.close();
                                }
                            });
                        });

                        formInvoice.submit();
                    }
                }).catch(e.preventDefault());
            }else{
                swal({
                    title: "¡UPS!",
                    text: "Parece que aún hay campos por llenar, verifica tu información e intenta de nuevo.",
                    icon: 'warning',
                    confirm: {
                        text: "Aceptar",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                });
            }
        }

    });

    const requestCurpData = function (curp){
        $.ajax({
            type: "POST",
            url: "/get_curp",
            data: {curp: curp},
            success: function(data) {
                $('input#first_name').val(data.nombres);
                $('input#last_name').val(data.apellido1);
                $('input#maiden_name').val(data.apellido2);
            },
            error: function(xhr, status, error) {
                console.log("Error on get CURP data: " + error);
            }
        });
    }

    /* Listener to is_foreign select */
    $('#is_foreign').change(function() {
        if($(this).val() === 'true'){
            $('#input-curp').addClass('hidden');
            $('input#curp').attr("disabled",true);
        }else{
            $('#input-curp').removeClass('hidden');
            $('input#curp').attr("disabled",false);
        }
    });
    /*********************************/



    /***MODAL PARA PAYMENTS******/
    var payment_modal = $('#payment-modal');
    payment_modal.on('show.bs.modal', function (event) {
        var button = $(event.relatedTarget); // Button that triggered the modal
        var title = button.data('title');
        var invoice = button.data('invoice');

        if(invoice){
            $('#invoice-form').removeClass('hidden');
        }else{
            $('#payment-form').removeClass('hidden');
        }
        payment_modal.find('.modal-title').text(title);
    });

    payment_modal.on('hidden.bs.modal', function () {
        $('#invoice-form').addClass('hidden');
        $('#payment-form').addClass('hidden');
    });

    /*******************************/
});
