import React from 'react';
import { toastr } from 'components/notifications';
import {
    update
} from "../services/request";
import $ from "jquery";
import swal from 'sweetalert';
import I18n from 'i18n-js';
I18n.locale = window.I18n.locale;

class ValidateVariablesRow extends React.Component {
    constructor(props) {
        super(props);
        let { process_validation, IdEstatusVariableBaja, Comentario } = props.data;
        this.state = {
            process_validation: process_validation,
            status: IdEstatusVariableBaja,
            motive: Comentario,
        };

        this.validate = this.validate.bind(this);
        this.reject = this.reject.bind(this);
        this.typeValidation = this.typeValidation.bind(this);
        this.classVariable = this.classVariable.bind(this);
    }

    validate() {
        let { data, user, validateStatusId } = this.props;
        update(
          { user: user.employee_number },
          `/dismissals_variables/${data.IdVariableBaja}/validate`).then(response => {
              toastr.success(I18n.t('messages.validate_variables.validated'));
              this.setState({
                  process_validation: true,
                  status: validateStatusId,
                  motive: ''
              });
        }).catch(err => {
            if (err.response.data.message){
                toastr.error(err.response.data.message);
            } else {
                toastr.error(I18n.t('messages.try_again'));
            }
        })
    }

    reject() {
        let { data, user, rejectStatusId } = this.props;

        swal('Motivo para rechazo', {
            content: "input",
            buttons: {
                cancel: I18n.t('messages.cancel'),
                confirm: {
                    text: I18n.t('messages.validate_variables.modal_text'),
                    closeModal: true
                }
            }
        }).then(function(motive) {
            if (motive) {
                update(
                    { motive: motive, user: user.employee_number },
                    `/dismissals_variables/${data.IdVariableBaja}/reject`).then(response => {
                    toastr.success(I18n.t('messages.validate_variables.rejected'));
                    this.setState({
                        process_validation: true,
                        status: rejectStatusId,
                        motive: response.data.Comentario
                    });
                }).catch(err => {
                    toastr.error(I18n.t('messages.try_again'));
                })
            }
        }.bind(this));
    }

    typeValidation() {
        let { validateStatusId } = this.props;
        let { status } = this.state;
        return status == validateStatusId ? I18n.t('messages.validate_variables.validate') : I18n.t('messages.validate_variables.reject');
    }

    classVariable() {
        let { validateStatusId } = this.props;
        let { status } = this.state;
        return status == validateStatusId ? 'm--font-success' : 'm--font-warning';
    }

    render() {
        let { data, index, user, unitPresIds } = this.props;
        let { process_validation, status, motive } = this.state;

        return (
            <div className="row form-group m-form__group">
              <div className="col-md-1 text-center">
                <strong>{ index + 1 }</strong>
              </div>
              <div className="col-md-2 text-center">
                <label className="form-label">
                  {data.variable_Descripcion}
                </label>
              </div>
              <div className="col-md-2 text-center">
                <label className="form-label">
                  {motive}
                </label>
              </div>
              <div className="col-md-2 text-center">
                <label className="form-label">
                  {data.variable_unidad_Descripcion}
                </label>
              </div>
                <div className="col-md-2 text-center">
                  <label className="form-label">
                    {data.variable_unidad_revisora_Descripcion}
                  </label>
                </div>
              <div className="col-md-1 text-center">
                { !process_validation &&
                  <label className="form-label m--font-info">
                    <b>{I18n.t('messages.no_validate')}</b>
                  </label>
                }
                { process_validation &&
                  <label className={`form-label ${ this.classVariable() }`}>
                    <b>{`Variable ${ this.typeValidation() }`}</b>
                  </label>
                }
              </div>
              { user.reviewer && user.audit && unitPresIds.includes(data.variable_IdUnidadRevisora) &&
                  <>
                    <div className="col-md-1 m--padding-left-5 m--padding-right-5">
                        <button type="button" className="btn btn-success btn-block" onClick={ this.validate }>{I18n.t('messages.validate')}</button>
                    </div>
                    <div className="col-md-1 m--padding-left-5 m--padding-right-5">
                        <button type="button" className="btn btn-warning btn-block" onClick={ this.reject }>{I18n.t('messages.reject')}</button>
                    </div>
                  </>
              }
              { !user.audit && user.reviewer &&
                  <>
                    <div className="col-md-1 m--padding-left-5 m--padding-right-5">
                        <button type="button" className="btn btn-success btn-block" onClick={ this.validate }>{I18n.t('messages.validate')}</button>
                    </div>
                    <div className="col-md-1 m--padding-left-5 m--padding-right-5">
                        <button type="button" className="btn btn-warning btn-block" onClick={ this.reject }>{I18n.t('messages.reject')}</button>
                    </div>
                  </>
              }
            </div>
        )
    }
}

export default ValidateVariablesRow;
