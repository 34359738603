import $ from "jquery";
import swal from "sweetalert";
import I18n from "i18n-js";

$(document).on('turbolinks:load', function (){
    let text = $('#collection_select_detalle :selected').text();
    if (text == 'N/A' || text == '') {
        $('#anexo_file').hide()
        $('#label_name_detalle').hide()
        if (text == 'N/A'){
            $('#post_file').val('Asignar N/A')
        }
    }else{
        $('#anexo_file').show()
        $('#label_name_detalle').show()
        $('#post_file').val('Cargar Archivo')
    }

    if ($('#field_file').is(":visible")){
        $("#post_file").removeAttr("disabled");
    }else{
        if ($('#collection_select_detalle :selected').text() != 'N/A'){
            $("#post_file").attr("disabled", true);
        }
    }

    $('#collection_select_detalle').on('change', function (){
        let text = $('#collection_select_detalle :selected').text();
        if (text == 'N/A' || text == '') {
            $('#anexo_file').hide()
            $('#label_name_detalle').hide()
            $('#post_file').val('Asignar N/A')
        }else{
            $('#anexo_file').show()
            $('#label_name_detalle').show()
            $('#post_file').val('Cargar Archivo')
        }
    })

    $('#collection_select_option').on('change', function (){
        let text = $('#collection_select_option :selected').text();
        if (text == 'N/A') {
            $('#label_name').hide()
            $('#label_file').hide()
            $('#post_file').val('Asignar N/A')
        }else{
            $('#label_name').show()
            $('#label_file').show()
            $('#post_file').val('Cargar Archivo')
        }
    })

    $('.btn-warning').click(function() {
        let element,resource, resourceId, url
        element = $(this)
        resource = element.data('resource');
        resourceId = element.data('resource-id');
        url = `/${resource}/${resourceId}`;
        console.log(resource)
        console.log(resourceId)
        $.ajax({
            url: url,
            data: { resource_id: resourceId },
            type: 'GET',
            dataType: 'json',
            success: function(data) {
                document.getElementById('image').innerHTML = ""
                $('#title').text(data.filename)
                let DOM_a = document.getElementById('image')
                var DOM_img = new Image(300,500);
                DOM_img.src = 'data:image/*;base64,' + data.encoded_file;

                DOM_a.appendChild(DOM_img);
                $('#selected_file_modal').modal('show')
            },
            error: function(error) {

            }
        });
    });
    $('#delete_na').click(function() {
        console.log("Entre a na-----")
        let element, resource, resourceId, url, authenticityToken;
        element = $(this);
        resource = element.data('resource');
        resourceId = element.data('resource-id');
        url = `/${resource}/${resourceId}`;
        authenticityToken = $('[name="csrf-token"]')[0] && $('[name="csrf-token"]')[0].content;
        $.ajax({
            url: url,
            data: { authenticity_token: authenticityToken },
            type: 'DELETE',
            dataType: 'json',
            success: function(data) {
                location.reload()
            },
            error: function(error) {
                console.log(error)
                let message = I18n.t('messages.try_again');
                if (error.responseJSON && error.responseJSON.message) {
                    message = error.responseJSON.message;
                }
                swal(I18n.t('messages.error'), message, 'error');
            }
        });
    });
})
