import $ from "jquery";
import swal from "sweetalert";
import I18n from "i18n-js";
import Rails from '@rails/ujs';

$(document).on('turbolinks:load', function () {
    const swalOpts = {
        buttons: {
            cancel: I18n.t('messages.cancel'),
            confirm: {
                text: I18n.t('messages.confirm'),
                closeModal: true
            }
        }
    };


    // Behavior after click to confirm button
    const confirmed = (element, result) => {
        if (result) {
            // Removing attribute for unbinding JS event.
            element.removeAttribute('data-confirm-swal');
            // Following a destination link
            //element.click();
            let btn_submit = document.getElementById("btn_submit");
            btn_submit.click();
        }
    };

// Display the confirmation dialog
    const showConfirmationDialog = (element) => {
        const message = element.getAttribute('data-confirm-swal');
        const text    = element.getAttribute('data-text');

        swal( 'Se modificarán tus datos, ¿Deseas continuar?', {
            icon: 'warning',
            buttons: {
                cancel: I18n.t('messages.cancel'),
                confirm: {
                    text: "Guardar",
                    closeModal: true
                },
            }
        }).then(result => confirmed(element, result));

        /*
        swal({
            title:             message || 'Se modificarán tus datos, ¿Deseas continuar?',
            text:              text || '',
            icon:              'warning',
            showCancelButton:  true,
            confirmButtonText: 'Yes',
            cancelButtonText:  'Cancel',
        }).then(result => confirmed(element, result));;*/
    };

    const allowAction = (element) => {
        if (element.getAttribute('data-confirm-swal') === null) {
            return true;
        }

        showConfirmationDialog(element);
        return false;
    };

    function handleConfirm(element) {
        if (!allowAction(this)) {
            Rails.stopEverything(element);
        }
    }

    Rails.delegate(document, 'a[data-confirm-swal]', 'click', handleConfirm);


});
