import $ from 'jquery';
import { cascadeSelects } from 'components/general';
import swal from "sweetalert";
import I18n from "i18n-js";

$(document).on('turbolinks:load', function () {
    let $idUnidad = $('#IdUnidad');
    if ($idUnidad.length) {
        let $idUnidadRevisora = $('#variable_IdUnidadRevisora');
        let url = $idUnidad.data('url');
        cascadeSelects($idUnidad, $idUnidadRevisora, url);
    }
});
